.modal .modal-header h3.sv-dialog-title {
  font-size: 1.25rem;
}

.modal .modal-header button.sv-dialog-close-button {
  font-size: 42px;
  opacity: 0.7;
}

.modal .modal-header button.sv-dialog-close-button:hover {
  opacity: 1;
}

.modal .modal-footer .sv-dialog-footer-button {
  font-size: 0.875rem;
}

@media (max-width: 580px) {
  .sv-dialog-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal .sv-dialog-form-container .controls {
    margin-left: 0;
  }

  .modal .sv-dialog-form-container .sv-dialog-form-label {
    float: none;
    text-align: revert;
  }

  .modal {
    width: unset;
  }
}
